<template>
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row mb-3">
                        <div class="col-lg-12">
                            <div class="card w-100 bg-current p-lg-3 p-2 border-0 rounded-lg d-block float-left">
                                <img :src="'/images/teacher.png'" alt="icon" class="sm-mt-2 w75 position-relative top--25 float-left mr-2 mt--1 ">
                                <h2 class="display1-size display2-md-size d-inline-block float-left mb-0 text-white fw-700"><span class="font-xssss fw-700 text-white d-block mb-1 ml-1">Welcome to</span> Dashboard </h2>
                            </div>
                        </div>            
                    </div> 
                    <div class="form-group p-3 border-light border p-2 shadow-xs bg-white rounded-lg">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group icon-input mb-0">
                                    <i class="ti-search font-xs text-grey-400"></i>
                                    <input type="text" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100" :value="`Cycle ${cycle}`" disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-3 p-3 border-light border p-2 shadow-xs bg-white rounded-lg">
                        <div v-if="!isLoad" class="row">
                            <div class="col-12">
                                <div v-if="(syllabus.length || syllabus.point) && syllabus.point.length" class="row mb-4">
                                    <div class="col-12">
                                        <div class="p-2 rounded-lg">
                                            <div class="row p-2">
                                                <button v-for="(item, index) in syllabus.point" :key="index" @click="setContent(item)" :class="{'bg-current text-white': item == content, 'bg-grey text-black': item != content}" class="btn-round-xxxl fw-800 font-xs rounded-lg mr-2">
                                                    {{index+1}}
                                                </button>
                                            </div>
                                            <hr v-if="content">
                                            <div class="row justify-content-between p-2" v-if="content">
                                                <div class="col-12 mb-3">
                                                    <table class="w-100">
                                                        <tr>
                                                            <td width="20%"><h3 class="fw-700">Name</h3></td>
                                                            <td><h3 class="fw-700 text-current">: {{content.content}}</h3></td>
                                                        </tr>
                                                        <tr>
                                                            <td width="20%"><h3 class="fw-700">Description</h3></td>
                                                            <td><h3 class="fw-700 text-current">: {{content.description}}</h3></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-12 mb-2">
                                                            <div id="accordion" class="accordion mb-0">
                                                                <div class="card shadow-xss mb-0">
                                                                    <div class="card-header bg-greylight theme-dark-bg" id="heading">
                                                                        <h5 class="mb-0"><button class="btn font-xsss fw-600 btn-link" data-toggle="collapse" data-target="#collapse" aria-expanded="false" aria-controls="collapsei"> Target </button></h5>
                                                                    </div>
                                                                    <div v-if="content.target.length" id="collapse" class="collapse p-3 show" aria-labelledby="heading" data-parent="#accordion">
                                                                        <div class="card-body d-flex p-2"  v-for="(item, i) in content.target" :key="i">
                                                                            <span class="bg-current btn-round-xs rounded-lg font-xssss text-white fw-600">{{i+1}}</span>
                                                                            <span class="font-xssss fw-500 text-grey-500 ml-2">{{item.content}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div v-else id="collapse" class="collapse p-3 show" aria-labelledby="heading" data-parent="#accordion">
                                                                        <div class="card-body d-flex p-2 text-center">
                                                                            <p class="text-black fw-600 m-0">Target is Empty!</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="row">
                                    <div class="col-12 text-center py-3">
                                        <img :src="'/images/empty.png'" alt="" width="300">
                                        <h1 class="text-muted">Data don't exist</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="row">
                            <div class="col-12 text-center py-3">
                                <div class="my-3">
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow text-warning mx-3" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>   
</template>

<script>
import axios from 'axios';

export default {
    name: `MyCourseDashboard`,
    data(){
        return {
            isLoad : true,
            syllabus:[],
            cycles: [],
            cycle: '',
            editable: false,
            content: null,
            paramsId: this.$route.params.idCourse,
        }
    },
    created() {
        this.getCycles()
    },
    methods: {
        async getSyllabus(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/syllabus?slug=${this.paramsId}&cycle=${this.cycle}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.syllabus = res.data.data
                this.isLoad = false
            }).catch((err) => console.error(err))
        },
        async getCycles(){
            if(localStorage.getItem(`${this.paramsId}-cycles`)) {
                this.cycles = JSON.parse(localStorage.getItem(`${this.paramsId}-cycles`))
            } else {
                await axios.get(`${process.env.VUE_APP_URL_API}/core/course/cycles?slug=${this.paramsId}&is_active=true`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.cycles = res.data.data
                    for(let i = 0; i < this.cycles.length; i++) {
                        if(this.cycles[i].active) {
                            this.cycle = this.cycles[i].cycle
                            break;
                        }
                    }
                }).catch((err) => console.error(err))
            }
            if(localStorage.getItem(`${this.paramsId}-cycle`)) {
                this.cycle = localStorage.getItem(`${this.paramsId}-cycle`)
            } else {
                this.cycle = this.cycles[0].cycle
            }
            this.getSyllabus()
        },
        setContent(syllabus) {
            this.content = syllabus
        },
        changeCycle(){
            this.isLoad = true
            this.content = null
            this.getSyllabus()
        }
    },
}
</script>

<style scoped>
    button{
        background-color: transparent;
        border: none;
    }
</style>